import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./header.css"

const Header = ({ siteTitle }) => (
  <header>
    <nav>
      <Link to="/" className="logo">
        {siteTitle}
      </Link>

      {/* <ul>
        <li className="first-nav">
          <Link to="/" activeClassName="active">
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" activeClassName="active">
            About
          </Link>
        </li>
     
        <li>
          <Link to="/contact" activeClassName="active">
            Get in touch
          </Link>
        </li>
      </ul> */}
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
